export default class OrderModel {
  customerId = 0;
  items = [
    // {
    //   productId= 0
    //   quantity= 0
    //   colorAndTypeId=0
    // }
  ];
  orderDate = new Date();
  status = 1;
  totalPrice = 0;
}
