import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Loader, Modal, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { CustomerState } from "../../Atoms/customer.atom";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import AddEdit from "./AddEdit.component";
import format_number from "../../Helpers/number_formatter";
import { OrderStatus } from "../../Constants/types";
import { orderState } from "../../Atoms/odrder.atom";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { ENDPOINTS } from "../../Api/enpoints";
export default function Orders({ isGros = false, ...props }) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 20,
    isGros: false,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [show, setshow] = useState(0);
  const [show2, setshow2] = useState(0);
  const [model, setmodel] = useRecoilState(orderState);
  const [deliverycompanies, setdeliverycompanies] = useState([]);
  const [products, setproducts] = useState([]);
  const [deliveryModel, setDeliveryModel] = useState({
    remark: "",
    orderId: 0,
    beginProcessDate: new Date(),
    endDate: new Date(),
    deliveryCompanyId: 0,
    status: 1,
  });
  const [packs, setpacks] = useState([]);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel({});
    setError("");
    setDeliveryModel({
      remark: "",
      orderId: 0,
      beginProcessDate: new Date(),
      endDate: new Date(),
      deliveryCompanyId: 0,
      status: 1,
    });
  };
  // API CALLS
  const createDelivery = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Delivery)
      .create(deliveryModel)
      .then((res) => {
        fetch();
        reset();
        setshow2(0);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Element a été bien ajouté !",
          showConfirmButton: false,
          timer: 1500,
        });
        setstate((prev) => {
          return { ...prev, open: false, loading: false };
        });
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };
  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        settotalCount(res.data.totalCount);
        let _prods = [];
        res.data.data.map((el) =>
          el.items.map((item) => {
            if (!_prods.find((p) => p.id == item.productId))
              _prods.push(item.product);
          })
        );
        console.log(_prods);
        setproducts(_prods);
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = { ...model };
    m.items = m.items.map((el) => {
      let _el = { ...el };
      delete _el.id;
      delete _el.product;
      _el.quantity = parseInt(_el.quantity);

      return _el;
    });
    // m.totalPrice=m.items.reduce((a=0,b)=>{
    //   return a+(b.quantity * )
    // })
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order)
        .update(model.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryCompany + "/getAll", {})
      .fetchAll()
      .then((res) => {
        setdeliverycompanies(res.data);
      })
      .catch((e) => console.log(e.Message));
  }, []);
  useEffect(() => {
    setfilterModel((prev) => ({ ...prev, isGros }));
  }, [isGros]);
  useEffect(
    () => fetch(),
    [filterModel.isGros, filterModel.take, filterModel.page]
  );
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Statut: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(OrderStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        noExport
        size="md"
        save={save}
        AddComponent={<AddEdit error={error} />}
      />
      <br></br>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        actions={[
          {
            label: "Changer état",
            action: (dataKey) => {
              setshow(dataKey);
            },
            render: (v) => (
              <button
                style={{
                  color: "rgba(67,55,160,1)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(67,55,160,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
          {
            label: "Confirmer la livraison",
            action: (dataKey) => {
              setshow2(dataKey);
              try {
                setpacks(
                  JSON.parse(data.find((el) => el.id == dataKey).packagingJSON)
                );
              } catch {}
            },
            render: (v) => (
              <button
                style={{
                  color: "rgba(167,55,60,1)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(67,55,160,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
        ]}
        deleteAction={deleteAction}
        actionKey="id"
        //   noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
      {show ? (
        <Modal
          size="md"
          overflow={false}
          style={{ maxHeight: "calc(100vh - 50px)", overflow: "auto" }}
          open={show > 0}
          onClose={() => {
            setshow(0);
          }}
        >
          <Modal.Header>
            <Modal.Title>Changer l'état du commande</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ maxHeight: "calc(100vh - 240px)", overflow: "auto" }}>
              <label>Statut: </label>
              <SelectPicker
                searchable={false}
                data={[{ label: "Tout", value: 0 }].concat(OrderStatus)}
                block
                noSearch
                value={data.find((el) => el.id == show).status}
                onSelect={async (status) => {
                  let d = [...data];
                  d.find((el) => el.id == show).status = status;
                  setdata((prev) => d);
                  let res = await createAPIEndpoint(
                    ENDPOINTS.Order + "/changeStatus/" + show + "/" + status
                  ).update2({});
                  if (res) setshow(0);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={save} appearance="primary">
              {state.loading ? <Loader size="sm" /> : "Enregistrer"}
            </Button>
            <Button
              onClick={() => {
                setshow(0);
              }}
              appearance="subtle"
            >
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      {show2 ? (
        <Modal
          size="md"
          overflow={false}
          style={{ maxHeight: "calc(100vh - 50px)", overflow: "auto" }}
          open={show2 > 0}
          onClose={() => {
            setshow2(0);
            setpacks([]);
          }}
        >
          <Modal.Header>
            <Modal.Title>Lancer une livraison:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ maxHeight: "calc(100vh - 240px)", overflow: "auto" }}>
              <h6>Les Colis :</h6>
              <div style={{ display: "" }}>
                {packs.map((p, i) => (
                  <PackComp p={p} i={i + 1} products={products}></PackComp>
                ))}
              </div>
              <label>Societé: </label>
              <SelectPicker
                searchable={false}
                data={deliverycompanies.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                block
                noSearch
                value={deliveryModel.deliveryCompanyId}
                onSelect={(deliveryCompanyId) =>
                  setDeliveryModel((prev) => ({
                    ...prev,
                    deliveryCompanyId,
                    orderId: show2,
                  }))
                }
              />
              <label>Remarque:</label>
              <Input
                placeholder="Remarque"
                as="textarea"
                value={deliveryModel.remark}
                onChange={(remark) => {
                  setDeliveryModel((prev) => ({ ...prev, remark }));
                }}
              />
              <label>Date de mis en livraison:</label>
              <Input
                placeholder="Remarque"
                type="date"
                value={deliveryModel.beginProcessDate}
                onChange={(beginProcessDate) => {
                  setDeliveryModel((prev) => ({ ...prev, beginProcessDate }));
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={createDelivery} appearance="primary">
              {state.loading ? <Loader size="sm" /> : "Enregistrer"}
            </Button>
            <Button
              onClick={() => {
                setshow2(0);
                setpacks([]);
              }}
              appearance="subtle"
            >
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

const columns = [
  {
    value: "reference",
    name: "Réf",
    render: (v) => <strong style={{ color: "indigo" }}>{v}</strong>,
  },
  {
    value: "customer",
    name: "Client",
    render: (v) =>
      v ? (
        <b>
          <b style={{ fontSize: "18px" }}>{v.fullName}</b>{" "}
          <i style={{ color: "#aaa" }}>({v.address})</i>
          <br></br>
          <a href={"tel:" + v.phoneNumber}>
            <b style={{ color: "rgb(95,186,98)" }}>{v.phoneNumber}</b>
          </a>
        </b>
      ) : (
        <b></b>
      ),
  },

  {
    value: "orderDate",
    name: "Date",
    render: (v) => <strong>{moment(v).format("L")}</strong>,
  },

  {
    value: "totalPrice",
    name: "Prix Total",
    render: (v) => <b style={{ color: "green" }}>{v && format_number(v)}</b>,
  },
  {
    value: "totalDeliveryCost",
    name: "Cout livraison Total",
    render: (v) => <b style={{ color: "green" }}>{v && format_number(v)}</b>,
  },
  {
    value: "status",
    name: "Statut",
    render: (v) => (
      <Tag
        color={
          v == 1
            ? "blue"
            : v == 2
            ? "yellow"
            : v == 3
            ? "violet"
            : v == 4
            ? "green"
            : "red"
        }
      >
        {v && OrderStatus.find((el) => el.value == v).label}
      </Tag>
    ),
  },
];

const PackComp = ({ p, i, products }) => {
  useEffect(() => {
    console.log(p);
  }, []);
  return (
    <div
      style={{
        width: "300px",
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        margin: "2px",
        display: "inline-block",
        verticalAlign: "top",
        backgroundColor: "rgb(236,182,28,0.2)",
      }}
    >
      <h6>Coli N° {i}</h6>
      <ul>
        {p.products.map((l) => (
          <li>
            {" "}
            {l.quantity} _ {products.find((el) => el.id == l.productId).name_fr}{" "}
            ({l.color_name_fr})
            <div>
              <strong> Réf: </strong>
              <span style={{ color: "green", padding: "0 5px" }}>
                {l.reference}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
