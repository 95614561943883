import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Grid from "../../Components/Grid";
import UserModel from "../../Models/UserModel";
import AddEdit from "./AddEdit.component";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
export default function Users({ isVendor, ...props }) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 20,
    isVendor: false,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(new UserModel());

  // ATOMS
  // HELPERS
  const reset = () => {
    setmodel(new UserModel());
    setError("");
  };
  // API CALLS
  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Accounts, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data.filter((u) => u.userName != "admin"));
        settotalCount(res.data.totalCount);
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Accounts)
        .update(model.id, model)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Accounts)
        .create(model)
        .then((res) => {
          fetch();
          reset();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Accounts)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => fetch(), [filterModel]);
  useEffect(() => {
    setfilterModel((prev) => ({ ...prev, isVendor }));
    setmodel((prev) => ({ ...prev, isVendor }));
  }, [isVendor]);
  return (
    <div>
      {" "}
      <Filter search={() => fetch()}>
        <Responsive className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        noExport
        size="md"
        save={save}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />
      <Grid
        editAction={(id) => {
          getBYId(id);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "firstName",
    value2: "lastName",
    name: "Nom",
    render: (v, v1) => <a>{v + " " + v1}</a>,
  },

  {
    value: "email",
    name: "Email",
    render: (v) => <b>{v}</b>,
  },

  {
    value: "phoneNumber",
    name: "Tél",
    render: (v) => <b style={{ color: "green" }}>{v}</b>,
  },
];
