import { Input, Message } from "rsuite";
import { FileD } from "../../../Components/media_library";
import { BASE_URL } from "../../../Config/api.config";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      <label>Nom Arabe:</label>
      <Input
        onChange={(name_ar) => {
          _setmodel((prev) => {
            return { ...prev, name_ar };
          });
        }}
        value={model.name_ar}
      />
      <br></br>
      <label>Nom Français:</label>
      <Input
        onChange={(name_fr) => {
          _setmodel((prev) => {
            return { ...prev, name_fr };
          });
        }}
        value={model.name_fr}
      />
      <br></br>
      <label>Nom Englais:</label>
      <Input
        onChange={(name_en) => {
          _setmodel((prev) => {
            return { ...prev, name_en };
          });
        }}
        value={model.name_en}
      />
      {model.cover && (
        <img
          style={{
            display: "block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
          }}
          src={BASE_URL + "Uploads/" + model.cover}
        ></img>
      )}
      <FileD
        single={(cover) => {
          console.log(cover);
          if (cover) _setmodel((prev) => ({ ...prev, cover: cover.path }));
        }}
      ></FileD>
      <br></br>

      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
