import { Input, Message, SelectPicker, TagInput } from "rsuite";

function AddEdit({ _setmodel, error, model ,isVendor }) {
  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(lastName) => {
          _setmodel((prev) => {
            return { ...prev, lastName };
          });
        }}
        value={model.lastName}
      />
      <label>Prénom:</label>
      <Input
        onChange={(firstName) => {
          _setmodel((prev) => {
            return { ...prev, firstName };
          });
        }}
        value={model.firstName}
      />
      <label>Code</label>
      <Input
        value={model.code}
        onChange={(code) => {
          _setmodel((prev) => {
            return { ...prev, code };
          });
        }}
      />

      <label>Télephone</label>
      <Input
        value={model.phoneNumber}
        onChange={(phoneNumber) => {
          _setmodel((prev) => {
            return { ...prev, phoneNumber };
          });
        }}
      />
      <label>Email</label>
      <Input
        type="email"
        value={model.email}
        onChange={(email) => {
          _setmodel((prev) => {
            return { ...prev, email, userName: email };
          });
        }}
      />
      {/* {isVendor && (
        <>
          {" "}
          <label>Vendeur</label>
          <span style={{ width: "50px", display: "block" }}>
            <input
              type="checkbox"
              value={model.isVendor}
              onChange={(email) => {
                _setmodel((prev) => {
                  return { ...prev, isVendor: !prev.isVendor };
                });
              }}
            />
          </span>
        </>
      )} */}
      <label>Mot de passe</label>
      <Input
        type="password"
        value={model.password}
        onChange={(password) => {
          _setmodel((prev) => {
            return { ...prev, password };
          });
        }}
      />

      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
