import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Input, Message, SelectPicker, TagInput } from "rsuite";
import { APi } from "../../Api";
import { DateRange } from "react-date-range";
import { PromotionState } from "../../Atoms/promotion.atom";
import { convertToRaw, EditorState } from "draft-js";
import Responsive from "../../Components/Responsive";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import ReactMediaLibraryWrapper from "../media_library";
function AddEdit({ error, model, setmodel }) {
  const [products, setproducts] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState(() =>
    EditorState.createEmpty()
  );
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  // const [q, setq] = useState("");

  return (
    <>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Titre Arabe:</label>
        <Input
          onChange={(name_ar) => {
            setmodel((prev) => {
              return { ...prev, name_ar };
            });
          }}
          value={model.name_ar}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Titre Français:</label>
        <Input
          onChange={(name_fr) => {
            setmodel((prev) => {
              return { ...prev, name_fr };
            });
          }}
          value={model.name_fr}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Titre Englais:</label>
        <Input
          onChange={(name_en) => {
            setmodel((prev) => {
              return { ...prev, name_en };
            });
          }}
          value={model.name_en}
        />
      </Responsive>
      <hr></hr>
      <label>Afficher au début : </label>
      <span style={{ width: "50px", display: "block" }}>
        <input
          checked={model.show}
          type="checkbox"
          value={model.show}
          onChange={(email) => {
            setmodel((prev) => {
              return { ...prev, show: !prev.show };
            });
          }}
        />
      </span>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Description Arabe:</label>
        <Editor
          textAlignment="right"
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let description_ar = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, description_ar }));
            setEditorState(st);
          }}
        />
        {/* <textarea
      disabled
      value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    /> */}
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Description Français:</label>

        <Editor
          editorState={editorState1}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let description_fr = draftToHtml(
              convertToRaw(editorState1.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, description_fr }));
            setEditorState1(st);
          }}
        />
        {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Description Englais:</label>

        <Editor
          editorState={editorState2}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let description_en = draftToHtml(
              convertToRaw(editorState2.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, description_en }));
            setEditorState2(st);
          }}
        />
        {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
      </Responsive>

      <label>Interval de temps :</label>
      {/*  */}
      {/* {model.endDate.toString()} */}
      <br></br>
      <div>
        <DateRange
          editableDateInputs={true}
          onChange={(item) =>
            // setState([item])
            setmodel((pv) => ({
              ...pv,
              dateFrom: item.selection.startDate,
              dateTo: item.selection.endDate,
            }))
          }
          moveRangeOnFirstSelection={false}
          // ranges={state}
          ranges={[
            {
              startDate: new Date(model.dateFrom),
              endDate: new Date(model.dateTo),
              key: "selection",
            },
          ]}
        />
      </div>
      <br></br>
      {/* {model.productId ? (
        <div>
          {" "}
          ancien prix : {
            products.find((el) => el.id == model.productId).price
          }{" "}
        </div>
      ) : (
        ""
      )}{" "} */}

      <h4>Photo de couverture:</h4>
      <ReactMediaLibraryWrapper
        picker
        reset={model.cover}
        onPick={(el) => {
          if (model.cover && model.cover.id == el.id) {
            setmodel((prev) => ({
              ...prev,
              cover: null,
              coverId: null,
            }));
          } else {
            setmodel((prev) => ({
              ...prev,
              cover: el,
              coverId: el.id,
            }));
          }
        }}
      />
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
