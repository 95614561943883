import { Checkbox, Input, Message } from "rsuite";
import { FileD } from "../../Components/media_library";
import { BASE_URL } from "../../Config/api.config";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      {model.logo && (
        <img
          style={{
            display: "block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
          }}
          src={BASE_URL + "Uploads/" + model.logo}
        ></img>
      )}
      <FileD
        single={(cover) => {
          console.log(cover);
          if (cover) _setmodel((prev) => ({ ...prev, logo: cover.path }));
        }}
      ></FileD>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />
      <label>Code Accés:</label>
      <Input
        onChange={(accessCode) => {
          _setmodel((prev) => {
            return { ...prev, accessCode };
          });
        }}
        value={model.accessCode}
      />
      <label>Status:</label>
      <input
        type="checkbox"
        checked={model.isactive}
        onChange={() => {
          _setmodel((prev) => {
            console.log(prev.isactive);
            return { ...prev, isactive: !prev.isactive };
          });
        }}
      />
      <br></br>

      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
