export default class CustomerModel {
  fullName = "";

  userId = null;

  address = "";

  phoneNumber = "";
  email = "";
}
