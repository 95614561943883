import * as React from "react";

function SvgPsd(props) {
  return (
    <svg className="icon file-icon file-icon--psd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.607 24" {...props}>
      <path className="file-icon__shadow" d="M19.592 7.219v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003-5.451-5.599-.001-.001a.338.338 0 0 0-.238-.102h-.001l-.005-.001H2.947a1.71 1.71 0 0 0-1.708 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V7.221v-.002z" />
      <path className="file-icon__outline" d="M18.354 5.951v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003L12.801.104 12.8.103a.338.338 0 0 0-.238-.102h-.001L12.556 0H1.708A1.71 1.71 0 0 0 0 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V5.953l.001-.002zm-5.457-4.768l4.305 4.422h-4.305V1.183zm3.749 20.881H1.708c-.565 0-1.025-.46-1.025-1.025V1.708c0-.565.46-1.025 1.025-1.025h10.506v5.264c0 .189.153.342.342.342h5.115v14.75a1.027 1.027 0 0 1-1.025 1.025z" />
      <path className="file-icon__outline" d="M5.968 15.424h-.991v1.031h-.661v-3.504c.551 0 1.101-.005 1.652-.005 1.711 0 1.717 2.478 0 2.478zm-.991-.606h.991c.846 0 .841-1.241 0-1.241h-.991v1.241z" />
      <path className="file-icon__outline" d="M9.908 13.798c-.12-.2-.45-.391-.831-.391-.491 0-.726.205-.726.466 0 .305.36.39.781.439.73.091 1.411.281 1.411 1.116 0 .781-.69 1.116-1.472 1.116-.715 0-1.266-.22-1.526-.86l.551-.285c.155.385.561.556.986.556.416 0 .806-.146.806-.526 0-.33-.345-.465-.811-.515-.716-.086-1.376-.275-1.376-1.062 0-.721.711-1.016 1.356-1.021.545 0 1.111.154 1.376.695l-.525.272zM14.185 14.674c.015.886-.525 1.781-1.752 1.781h-1.376v-3.504h1.376c1.201 0 1.736.857 1.752 1.723zm-2.473 1.145h.721c.796 0 1.111-.58 1.096-1.151-.015-.545-.335-1.091-1.096-1.091h-.721v2.242z" />
    </svg>
  );
}

export default SvgPsd;
