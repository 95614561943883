import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { PromotionState } from "../../Atoms/promotion.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import PromotionModel from "../../Models/PromotionModel";
import AddEdit from "./AddEdit.component";
export default function Promotions(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 20,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(PromotionState);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new PromotionModel());
    setError("");
  };
  // API CALLS
  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Promotion, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
      max_onStock: parseInt(model.max_onStock),
      min_onStock: parseInt(model.min_onStock),
      discount: parseInt(model.discount),
    };
    delete m.cover;
    if (model.id) {
      delete m.product;
      APi.createAPIEndpoint(APi.ENDPOINTS.Promotion)
        .update(model.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Promotion)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Promotion)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => fetch(), []);
  return (
    <div>
      {/* <Filter search={() => fetch()}>
        <Responsive className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
      </Filter> */}
      <ExportAdd
        noExport
        size="lg"
        save={save}
        AddComponent={<AddEdit error={error} />}
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "cover",
    name: "Couverture",
    render: (v) => {
      return v ? (
        <img
          alt=""
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
          }}
          src={BASE_URL + "Uploads/" + v.small_path}
        ></img>
      ) : (
        <span
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
            content: "",
          }}
        ></span>
      );
    },
  },
  {
    value: "name_fr",
    name: "Nom",
    render: (v) => (
      <a>
        <b>{v}</b>
      </a>
    ),
  },

  {
    name: "Stock Condition",
    value: "min_onStock",
    value2: "max_onStock",
    render: (v, v2) => (
      <a>
        <b>{"Min: " + v + " / Max: " + v2}</b>
      </a>
    ),
  },

  {
    value: "dateFrom",
    value2: "dateTo",
    name: "Validité",
    render: (v, v2) => (
      <b>
        {" "}
        <i>de </i>{" "}
        <strong style={{ color: "#999" }}>{moment(v).format("L")}</strong>{" "}
        <i> à </i>{" "}
        <strong style={{ color: "#999" }}>{moment(v2).format("L")}</strong>{" "}
      </b>
    ),
  },
  // {
  //   name: "Nouveau Prix",
  //   value: "newPrice",
  //   render: (v) => <i style={{ color: "#232323" }}>{v}</i>,
  // },
  {
    name: "Remise",
    value: "discount",
    render: (v) => <i style={{ color: "#e33" }}>{v}%</i>,
  },
];
