import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Input, Message, SelectPicker, TagInput } from "rsuite";
import { APi } from "../../Api";
import { DateRange } from "react-date-range";
import { PromotionState } from "../../Atoms/promotion.atom";
import { convertToRaw, EditorState } from "draft-js";
import Responsive from "../../Components/Responsive";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import ReactMediaLibraryWrapper from "../media_library";
function AddEdit({ error }) {
  const [model, setmodel] = useRecoilState(PromotionState);
  const [products, setproducts] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState(() =>
    EditorState.createEmpty()
  );
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  // const [q, setq] = useState("");
  const fetchProducts = (q) => {
    if (q.length >= 3) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Product, { q, page: 1, take: 20 })
        .fetchAll()
        .then((res) => {
          let _d = res.data.map((el) => ({ label: el.name_fr, value: el.id }));
          setproducts(res.data);
        })
        .catch((e) => console.log(e.Message));
    }
  };

  return (
    <>
      <label>Article :</label>
      <SelectPicker
        value={model.productId}
        onSelect={(productId) => setmodel((prev) => ({ ...prev, productId }))}
        defaultValue=""
        data={
          products.length
            ? products.map((el) => ({ label: el.name_fr, value: el.id }))
            : model.product
            ? [{ label: model.product.name_fr, value: model.product.id }]
            : []
        }
        onSearch={(q) => fetchProducts(q)}
        block
      />
      <Responsive className="p-10" xl={4} l={4}>
        <label>Nom Arabe:</label>
        <Input
          onChange={(name_ar) => {
            setmodel((prev) => {
              return { ...prev, name_ar };
            });
          }}
          value={model.name_ar}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Nom Français:</label>
        <Input
          onChange={(name_fr) => {
            setmodel((prev) => {
              return { ...prev, name_fr };
            });
          }}
          value={model.name_fr}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Nom Englais:</label>
        <Input
          onChange={(name_en) => {
            setmodel((prev) => {
              return { ...prev, name_en };
            });
          }}
          value={model.name_en}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Description Arabe:</label>
        <Editor
          textAlignment="right"
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let description_ar = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, description_ar }));
            setEditorState(st);
          }}
        />
        {/* <textarea
      disabled
      value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    /> */}
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Description Français:</label>

        <Editor
          editorState={editorState1}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let description_fr = draftToHtml(
              convertToRaw(editorState1.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, description_fr }));
            setEditorState1(st);
          }}
        />
        {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Description Englais:</label>

        <Editor
          editorState={editorState2}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let description_en = draftToHtml(
              convertToRaw(editorState2.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, description_en }));
            setEditorState2(st);
          }}
        />
        {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
      </Responsive>
      <Responsive className="p-10" xl={6} l={4} s={6}>
        <label>Nbre des articles Min:</label>
        <Input
          type="number"
          value={model.min_onStock}
          onChange={(min_onStock) => {
            setmodel((prev) => {
              return { ...prev, min_onStock };
            });
          }}
        />
      </Responsive>
      <Responsive className="p-10" xl={6} l={4} s={6}>
        <label>Nbre des articles Max:</label>
        <Input
          type="number"
          value={model.max_onStock}
          onChange={(max_onStock) => {
            setmodel((prev) => {
              return { ...prev, max_onStock };
            });
          }}
        />
      </Responsive>
      <label>Interval de temps :</label>
      {/*  */}
      {/* {model.endDate.toString()} */}
      <br></br>
      <div>
        <DateRange
          editableDateInputs={true}
          onChange={(item) =>
            // setState([item])
            setmodel((pv) => ({
              ...pv,
              dateFrom: item.selection.startDate,
              dateTo: item.selection.endDate,
            }))
          }
          moveRangeOnFirstSelection={false}
          // ranges={state}
          ranges={[
            {
              startDate: new Date(model.dateFrom),
              endDate: new Date(model.dateTo),
              key: "selection",
            },
          ]}
        />
      </div>
      <br></br>
      {/* {model.productId ? (
        <div>
          {" "}
          ancien prix : {
            products.find((el) => el.id == model.productId).price
          }{" "}
        </div>
      ) : (
        ""
      )}{" "} */}
      <label>Remise %:</label>
      <Input
        type="number"
        value={model.discount}
        onChange={(discount) => {
          setmodel((prev) => {
            return { ...prev, discount };
          });
        }}
      />
      <h4>Photo de couverture:</h4>
      <ReactMediaLibraryWrapper
        picker
        reset={model.cover}
        onPick={(el) => {
          if (model.cover && model.cover.id == el.id) {
            setmodel((prev) => ({
              ...prev,
              cover: null,
              coverId: null,
            }));
          } else {
            setmodel((prev) => ({
              ...prev,
              cover: el,
              coverId: el.id,
            }));
          }
        }}
      />
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
