import axios from "axios";
// import { htmlToText } from "html-to-text";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Modal, SelectPicker } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../../Api/";
import { createAPIEndpoint } from "../../../Api/authenticated.requests";
import { ENDPOINTS } from "../../../Api/enpoints";
import { exportAddAtom } from "../../../Atoms/exportAdd.atom";
import { productState } from "../../../Atoms/product.atom";
import ExportAdd from "../../../Components/Common/ExportAdd";
import Filter from "../../../Components/Common/Filter";
import Grid from "../../../Components/Grid";
import { BASE_URL } from "../../../Config/api.config";
import slugify from "../../../Helpers/slugify";
import validate from "../../../Helpers/validate";
import ProductModel from "../../../Models/productModel";
import AddEdit from "./addEdit.component";
import { useFacebook } from "react-facebook";
export default function Products(props) {
  // STATE
  const { isLoading, init, error } = useFacebook();

  async function handleClick() {
    const api = await init();

    const response = await api.login();
    console.log(response);
    const FB = await api.getFB(); // Get original FB object
    console.log(FB);
  }
  const [data, setdata] = useState([]);
  const [pDTypes, setpDTypes] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [showPublish, setshowPublish] = useState(0);
  const [filterModel, setfilterModel] = useState({ q: "", page: 1, take: 20 });
  // --- add edit model ---
  const [error1, setError] = useState("");
  const [model, setmodel] = useRecoilState(productState);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new ProductModel());
    setError("");
  }; // API CALLS
  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Product + "/GetColotypes", filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .catch((e) => setError(e.Message));
  };
  const fetchPDTypes = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryType, {})
      .fetchAll()
      .then((res) => {
        setpDTypes(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  const save = () => {
    let msg = validate(model, [{ name_fr: "Nom Français" }]);
    if (
      msg ||
      (model.productDeliveryTypeId &&
        pDTypes.find((el) => el.id == model.productDeliveryTypeId).maxWeight &&
        !model.weight)
    )
      setError(msg ? msg : "veuiller entrer le poids");
    else {
      setstate((prev) => {
        return { ...prev, loading: true };
      });
      let m = { ...model };
      m.slug = slugify(m.name_fr);
      if (m.tags) {
        console.log(m.tags);
        m.productTags = [...m.tags.map((el) => ({ tagId: el.id }))];
        console.log(m.tags);
        delete m.tags;
      }
      if (m.colorAndTypes) {
        let _mct = [...m.colorAndTypes];
        _mct = _mct.map((el) => {
          console.log(el);
          let med =
            el.medias && el.medias.length
              ? [...el.medias].map((mmm) => ({ mediaId: mmm.mediaId }))
              : [];
          console.log(med);
          console.log(el.medias);

          let _el = { ...el, medias: med };
          if (_el.woodType) {
            console.log(_el);
            delete _el.woodType;
          }
          if (_el.id.toString().length >= "1666800393856".length) delete _el.id;
          return _el;
        });
        m.colorAndTypes = _mct;
      }
      console.log(m);
      m.storeId = 4;
      delete m.defaultPicture;
      if (!m.productDeliveryTypeId) delete m.productDeliveryTypeId;
      if (model.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Product)
          .update(model.id, m)
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Product)
          .create(m)
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Product)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = async (id) => {
    const res = await createAPIEndpoint(ENDPOINTS.Product).fetchById(id);
    if (res.data) {
      setmodel(res.data);
    }
    setError("");
  };
  const postToFB = (v) => {
    let prod = data.find((el) => el.id == showPublish);
    let access_token = localStorage.getItem("fb_page_access_token");
    console.log(JSON.parse(access_token));
    if (access_token) {
      axios
        .post(
          // "https://graph.facebook.com/109911625232003/feed?" +
          //   "&message=" +
          //   (prod.name_fr +
          //     " \\n " +
          //     (prod.description_ar || prod.description_ar).replaceAll(
          //       /<[^>]+>/g,
          //       ""
          //     )) +
          //   "&access_token=" +
          //   JSON.parse(access_token) +
          //   "&link=https://www.royaumedubois.com/shop/"
          // +prod.slug
          "https://graph.facebook.com/109911625232003/photos?url=" +
            BASE_URL +
            "Uploads/" +
            prod.defaultPicture.path +
            "&message=" +
            (prod.name_fr +
              "%0D%0A" +
              (prod.description_ar || prod.description_ar).replaceAll(
                /<[^>]+>/g,
                ""
              )) +
            "%0D%0A https://www.royaumedubois.com/product/" +
            prod.slug +
            "&access_token=" +
            JSON.parse(access_token) +
            "&link=https://www.royaumedubois.com/product/" +
            prod.slug
        )
        .then((res) => console.log(res));
    }
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    fetchPDTypes();
  }, []);
  useEffect(async () => {
    const api = await init();

    const response = await api.login();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        {" "}
        <div className="p-10">
          {" "}
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </div>
      </Filter>
      <ExportAdd
        ActionOnClose={reset}
        size="xl"
        noExport
        save={save}
        AddComponent={<AddEdit error={error} pDTypes={pDTypes} />}
      />{" "}
      <button onClick={handleClick}>test </button>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        //   noAdvancedActions // for custom advanced actions
        actions={[
          {
            label: "publier sur page facebook",
            action: (id) => setshowPublish(id),
            render: (x) => <Button color="blue">{x}</Button>,
          },
        ]}
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
            setfilterModel((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
      <FbPublisher
        show={showPublish}
        setshow={setshowPublish}
        action={postToFB}
      />
    </div>
  );
}

const columns = [
  {
    value: "name_ar",
    value2: "defaultPicture",
    name: "Nom Arabe",
    render: (v, picture) => (
      <a>
        {picture ? (
          <img
            style={{
              display: "inline-block",
              width: "50px",
              height: "50px",
              borderRadius: "5px",
              background: "#eee",
            }}
            src={BASE_URL + "Uploads/" + picture.path}
          ></img>
        ) : (
          <span
            style={{
              display: "inline-block",
              width: "50px",
              height: "50px",
              borderRadius: "5px",
              background: "#eee",
              content: "",
            }}
          ></span>
        )}{" "}
        {v}
      </a>
    ),
  },
  {
    value: "name_fr",
    name: "Nom Français",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "name_en",
    name: "Nom Englais",
    render: (v) => <a>{v}</a>,
  },
];
const FbPublisher = ({ action, show, setshow, pages = [] }) => {
  const [q, setq] = useState("");
  const [selectedpage, setselectedpage] = useState(0);
  return (
    <Modal
      size="md"
      overflow={false}
      style={{ maxHeight: "calc(100vh - 50px)", overflow: "auto" }}
      open={show > 0}
      onClose={() => {
        setshow(0);
      }}
    >
      <Modal.Header>
        <Modal.Title>Publier Sur Facebook</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: "calc(100vh - 240px)", overflow: "auto" }}>
          <Input
            value={q}
            onChange={(v) => setq(v)}
            as="textarea"
            rows={3}
            block
          />
        </div>
        <label>Choisir Page: </label>
        <SelectPicker
          searchable={false}
          data={pages}
          block
          noSearch
          value={selectedpage}
          onSelect={(s) => {
            setselectedpage(s);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => action(q)} appearance="primary">
          Publier
        </Button>
        <Button
          onClick={() => {
            setshow(0);
          }}
          appearance="subtle"
        >
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
