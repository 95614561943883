import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import { BASE_URL } from "../../Config/api.config";
import validate from "../../Helpers/validate";
import AddEdit from "./addEdit.component";
export default function DeliveryCompany(props) {
  // STATE
  const [data, setdata] = useState([]);
  // --- add edit model ---
  const [model, setmodel] = useState({
    name: "",
    logo: "",
    isactive: false,
    accessCode: "",
  });
  const [rooms, setrooms] = useState([
    { nbAdu: 1, brrChild: 1, childAges: [1] },
  ]);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel({ name: "", logo: "", isactive: false, accessCode: "" });
  }; // API CALLS
  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryCompany + "/getAll", {})
      .fetchAll()
      .then((res) => {
        setdata(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  const save = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryCompany)
        .update(model.id, model)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryCompany)
        .create(model)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.DeliveryCompany)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => console.log(e.Message));
  };
  const getBYId = (id) => {
    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  const UpdatAdulte = (v, i,j) => {
    setrooms((pre) => {
      let res = [...pre]; // array of object room 
      let room = { ...res[i] }; // object room 
      room.nbAdu = v; /// chnage adult number value
      res[i] = room; // 
      return res;
    });
  };
  useEffect(() => fetch(), []);
  return (
    <div>
      <ExportAdd
        size="sm"
        noExport
        save={save}
        AddComponent={<AddEdit error={""} model={model} _setmodel={setmodel} />}
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
    </div>
  );
}

const columns = [
  {
    value: "name",
    value2: "logo",
    name: "Nom",
    render: (v, v2) => (
      <a>
        <img
          style={{ width: "60px", margin: "5px" }}
          src={BASE_URL + "Uploads/" + v2}
        ></img>
        {v}
      </a>
    ),
  },
  {
    value: "isactive",
    name: "Active",
    render: (v) => (
      <strong style={{ color: v ? "green" : "red" }}>
        {v ? "active" : "non active"}
      </strong>
    ),
  },
];

const fakeData = [
  {
    id: 1,
    name: "Dinar",
  },
];
