import { Checkbox, Input, Message } from "rsuite";
import { FileD } from "../../Components/media_library";
import { BASE_URL } from "../../Config/api.config";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />
      <label>Nombre Max:</label>
      <Input
        onChange={(maxNumber) => {
          _setmodel((prev) => {
            return { ...prev, maxNumber: parseInt(maxNumber) };
          });
        }}
        type="number"
        step={1}
        value={model.maxNumber}
      />
      <label>Remise sur 2éme:</label>
      <Input
        onChange={(secondDiscount) => {
          _setmodel((prev) => {
            return { ...prev, secondDiscount: parseInt(secondDiscount) };
          });
        }}
        type="number"
        step={1}
        value={model.secondDiscount}
      />
      <label>Poids Max (g):</label>
      <Input
        onChange={(maxWeight) => {
          _setmodel((prev) => {
            return { ...prev, maxWeight: parseFloat(maxWeight) };
          });
        }}
        type="number"
        step={0.1}
        value={model.maxWeight}
      />
      <label>Poids Additionnel Max (g):</label>
      <Input
        onChange={(additionalWeight) => {
          _setmodel((prev) => {
            return { ...prev, additionalWeight: parseFloat(additionalWeight) };
          });
        }}
        type="number"
        step={1}
        value={model.additionalWeight}
      />
      <label>Prix:</label>
      <Input
        onChange={(price) => {
          _setmodel((prev) => {
            return { ...prev, price: parseInt(price) };
          });
        }}
        type="number"
        step={0.1}
        value={model.price}
      />

      <br></br>

      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
