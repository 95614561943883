import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Message,
  SelectPicker,
  TagPicker,
  // AvatarGroup,
  Avatar,
} from "rsuite";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Toggle from "rsuite/Toggle";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Responsive from "../../../Components/Responsive";
import "./editor.scss";
import { SwatchesPicker } from "react-color";

import ReactMediaLibraryWrapper from "../../media_library";
import { APi } from "../../../Api";
import { createAPIEndpoint } from "../../../Api/authenticated.requests";
import { ENDPOINTS } from "../../../Api/enpoints";
import Grid from "../../../Components/Grid";
import { BASE_URL } from "../../../Config/api.config";
import { useRecoilState } from "recoil";
import { productState } from "../../../Atoms/product.atom";
function AddEdit({ error, pDTypes }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState(() =>
    EditorState.createEmpty()
  );
  const [categories, setcategories] = useState([]);
  const [woodTypes, setwoodTypes] = useState([]);
  const [tags, settags] = useState([]);
  const [selectedTags, setselectedTags] = useState([]);
  const [model, _setmodel] = useRecoilState(productState);

  const [active, setactive] = useState(0);
  const fetchTags = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Tag, { q }, "/autocomplete")
        .customGet()
        .then((res) => settags(res.data));
    }
  };
  const fetchCategories = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Category, {})
      .fetchAll()
      .then((res) => {
        let _d = res.data.map((el) => ({ label: el.name_fr, value: el.id }));
        setcategories((prev) => _d);
      })
      .catch((e) => console.log(e.Message));
  };
  const fetchWoodTypes = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.WoodType, {})
      .fetchAll()
      .then((res) => {
        setwoodTypes(res.data);
      })
      .catch((e) => console.log(e.Message));
  };

  useEffect(() => {
    fetchCategories();
    fetchTags();
    fetchWoodTypes();
  }, []);
  useEffect(() => {
    if (model.id) {
      console.log(model.productTags);
      setselectedTags((prev) =>
        model.productTags ? model.productTags.map((el) => el.tag) : []
      );
      if (model.description_ar) {
        const contentBlock = htmlToDraft(model.description_ar);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      }

      if (model.description_fr) {
        const contentBlock1 = htmlToDraft(model.description_fr);
        if (contentBlock1) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock1.contentBlocks
          );
          const editorState1 = EditorState.createWithContent(contentState);
          setEditorState1(editorState1);
        }
      }
      if (model.description_en) {
        const contentBlock1 = htmlToDraft(model.description_en);
        if (contentBlock1) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock1.contentBlocks
          );
          const editorState1 = EditorState.createWithContent(contentState);
          setEditorState2(editorState1);
        }
      }
    }
  }, [model.id]);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #eee",
          paddingBottom: "10px",
        }}
      >
        <Button
          style={{ borderRadius: 0 }}
          appearance={active ? "" : "primary"}
          onClick={() => setactive(0)}
        >
          Infos Géneral
        </Button>
        <Button
          style={{
            borderRadius: 0,
            borderLeft: "1px solid #666",
            borderRight: "1px solid #666",
          }}
          appearance={active != 1 ? "" : "primary"}
          onClick={() => setactive(1)}
        >
          Image par defaut
        </Button>
        <Button
          style={{ borderRadius: 0 }}
          appearance={active != 2 ? "" : "primary"}
          onClick={() => setactive(2)}
        >
          Couleurs & images
        </Button>
      </div>
      {!active ? (
        <div>
          <Responsive xl={2} l={3} className="p-10">
            <label>Catégorie</label>
            <br></br>
            <SelectPicker
              value={model.categoryId}
              onSelect={(categoryId) =>
                _setmodel((prev) => ({ ...prev, categoryId }))
              }
              defaultValue=""
              searchable={false}
              data={categories}
              block
            />
          </Responsive>

          <Responsive xl={6} l={6} className="p-10">
            <label>Mots Clés: </label>

            <TagPicker
              creatable
              data={
                tags
                  ? tags
                      .map((c) => {
                        return { label: c.name, value: c.id };
                      })
                      .filter((el) =>
                        selectedTags.find((ss) => ss.id == el.value)
                      )
                      .concat(
                        tags
                          .map((c) => {
                            return { label: c.name, value: c.id };
                          })
                          .filter(
                            (el) =>
                              !selectedTags.find((ss) => ss.id == el.value)
                          )
                      )
                  : []
              }
              block
              onSearch={fetchTags}
              onSelect={(s) => {
                let existed = selectedTags.filter((el) =>
                  s.find((_id) => _id == el.id)
                );
                let newTags = tags.filter(
                  (item) =>
                    s.find((_id) => _id == item.id) &&
                    !selectedTags.find((el) => el.id == item.id)
                );
                setselectedTags((prev) => {
                  return [...existed, ...newTags];
                });

                _setmodel((prev) => {
                  return { ...prev, tags: [...existed, ...newTags] };
                });
              }}
              onCreate={async (value, item) => {
                let r = await createAPIEndpoint(ENDPOINTS.Tag).create({
                  name: item.value,
                });
                console.log(r);
                if (r.data) {
                  _setmodel((prev) => {
                    console.log(prev.tags);
                    return {
                      ...prev,
                      tags: prev.tags.length
                        ? [...prev.tags, r.data]
                        : [r.data],
                    };
                  });
                  fetchTags();
                  setselectedTags((prev) => {
                    console.log(prev);
                    return prev.length ? [...prev, r.data] : [r.data];
                  });
                }
              }}
              value={selectedTags.map((c) => c.id)}
              defaultValue={selectedTags.map((c) => c.id.toString())}
              defaultChecked={selectedTags.map((c) => c.id)}
              onClean={() => {
                _setmodel((prev) => {
                  return { ...prev, tags: [] };
                });
                setselectedTags([]);
              }}
              onChange={(s) => {
                if (s) {
                  console.log(s);
                  let existed = selectedTags.filter((el) =>
                    s.find((_id) => _id == el.id)
                  );
                  let newTags = tags.filter(
                    (item) =>
                      s.find((_id) => _id == item.id) &&
                      !selectedTags.find((el) => el.id == item.id)
                  );
                  setselectedTags((prev) => {
                    return [...existed, ...newTags];
                  });

                  _setmodel((prev) => {
                    return { ...prev, tags: [...existed, ...newTags] };
                  });
                } else {
                  setselectedTags((prev) => {
                    return [];
                  });

                  _setmodel((prev) => {
                    return { ...prev, tags: [] };
                  });
                }
              }}
            />
          </Responsive>
          <div>
            <Responsive xl={6} l={6} className="p-10">
              <label>Type Du Produit (pour livraison)</label>
              <br></br>
              <SelectPicker
                value={model.productDeliveryTypeId}
                onSelect={(productDeliveryTypeId) =>
                  _setmodel((prev) => ({ ...prev, productDeliveryTypeId }))
                }
                defaultValue=""
                searchable={false}
                data={pDTypes.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                block
              />
            </Responsive>
            {model.productDeliveryTypeId &&
              pDTypes.find((el) => el.id == model.productDeliveryTypeId)
                .maxWeight && (
                <Responsive xl={6} l={6} className="p-10">
                  {" "}
                  <label>Poids:</label>
                  <Input
                    onChange={(weight) => {
                      _setmodel((prev) => {
                        return { ...prev, weight: parseFloat(weight) };
                      });
                    }}
                    block
                    type="number"
                    step={1}
                    value={model.weight}
                  />
                </Responsive>
              )}
          </div>
          <br></br>
          <Responsive xl={4} l={4} className="p-10">
            <label>Nombre de points pour un acticle:</label>
            <Input
              type="number"
              onChange={(pointOfferedPerUnit) => {
                _setmodel((prev) => {
                  return { ...prev, pointOfferedPerUnit:parseInt(pointOfferedPerUnit) };
                });
              }}
              value={model.pointOfferedPerUnit}
            />
          </Responsive>
          <br></br>
          <Responsive xl={4} l={4} className="p-10">
            <label>Nom Arabe:</label>
            <Input
              onChange={(name_ar) => {
                _setmodel((prev) => {
                  return { ...prev, name_ar };
                });
              }}
              value={model.name_ar}
            />
          </Responsive>
          <Responsive className="p-10" xl={4} l={4}>
            <label>Nom Français:</label>
            <Input
              onChange={(name_fr) => {
                _setmodel((prev) => {
                  return { ...prev, name_fr };
                });
              }}
              value={model.name_fr}
            />
          </Responsive>
          <Responsive className="p-10" xl={4} l={4}>
            <label>Nom Englais:</label>
            <Input
              onChange={(name_en) => {
                _setmodel((prev) => {
                  return { ...prev, name_en };
                });
              }}
              value={model.name_en}
            />
          </Responsive>
          <Responsive className="p-10" xl={4} l={4}>
            <label>Description Arabe:</label>
            <Editor
              textAlignment="right"
              editorState={editorState}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-editor"
              onEditorStateChange={(st) => {
                let description_ar = draftToHtml(
                  convertToRaw(editorState.getCurrentContent())
                );
                _setmodel((prev) => ({ ...prev, description_ar }));
                setEditorState(st);
              }}
            />
            {/* <textarea
      disabled
      value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    /> */}
          </Responsive>
          <Responsive className="p-10" xl={4} l={4}>
            <label>Description Français:</label>

            <Editor
              editorState={editorState1}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-editor"
              onEditorStateChange={(st) => {
                let description_fr = draftToHtml(
                  convertToRaw(editorState1.getCurrentContent())
                );
                _setmodel((prev) => ({ ...prev, description_fr }));
                setEditorState1(st);
              }}
            />
            {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
          </Responsive>
          <Responsive className="p-10" xl={4} l={4}>
            <label>Description Englais:</label>

            <Editor
              editorState={editorState2}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-editor"
              onEditorStateChange={(st) => {
                let description_en = draftToHtml(
                  convertToRaw(editorState2.getCurrentContent())
                );
                _setmodel((prev) => ({ ...prev, description_en }));
                setEditorState2(st);
              }}
            />
            {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
          </Responsive>
          {error && (
            <Message showIcon type="error">
              {error}
            </Message>
          )}
        </div>
      ) : active == 2 ? (
        <ColorMedias woodTypes={woodTypes} />
      ) : (
        <>
          {" "}
          <h4>Image par default</h4>
          <ReactMediaLibraryWrapper
            picker
            reset={model.defaultPicture}
            onPick={(el) => {
              if (model.defaultPicture && model.defaultPicture.id == el.id) {
                _setmodel((prev) => ({
                  ...prev,
                  defaultPicture: null,
                  defaultPictureId: null,
                }));
              } else {
                _setmodel((prev) => ({
                  ...prev,
                  defaultPicture: el,
                  defaultPictureId: el.id,
                }));
              }
            }}
            selected={
              model.defaultPictureId ? [{ id: model.defaultPictureId }] : []
            }
          />
        </>
      )}
    </>
  );
}

export default AddEdit;

export const ColorType = ({ add, woodTypes = [], model, setmodel, modify }) => {
  const [hidecolorPicker, setHidecolorPicker] = useState();
  const [selected, setselected] = useState();

  useEffect(() => {
    setselected(model.medias ? model.medias.map((el) => el.media) : []);
    console.log(model.medias);
  }, [model.medias]);
  return (
    <>
      <Responsive xl={3} l={3} className="p-10">
        <label>Réference :</label>
        <Input
          onChange={(reference) => {
            setmodel((prev) => {
              return { ...prev, reference };
            });
          }}
          value={model.reference}
        />
      </Responsive>
      <Responsive xl={3} l={3} className="p-10">
        <label>Nom Arabe:</label>
        <Input
          onChange={(color_name_ar) => {
            setmodel((prev) => {
              return { ...prev, color_name_ar };
            });
          }}
          value={model.color_name_ar}
        />
      </Responsive>
      <Responsive className="p-10" xl={3} l={3}>
        <label>Nom Français:</label>
        <Input
          onChange={(color_name_fr) => {
            setmodel((prev) => {
              return { ...prev, color_name_fr };
            });
          }}
          value={model.color_name_fr}
        />
      </Responsive>
      <Responsive className="p-10" xl={3} l={3}>
        <label>Nom Englais:</label>
        <Input
          onChange={(color_name_en) => {
            setmodel((prev) => {
              return { ...prev, color_name_en };
            });
          }}
          value={model.color_name_en}
        />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #eee",
            padding: "10px",
          }}
        >
          <Button
            style={{ borderRadius: 0 }}
            appearance={hidecolorPicker ? "" : "primary"}
            onClick={() => setHidecolorPicker(false)}
          >
            Couleur{" "}
          </Button>
          <Button
            style={{ borderRadius: 0 }}
            appearance={!hidecolorPicker ? "" : "primary"}
            onClick={() => setHidecolorPicker(true)}
          >
            Type du bois{" "}
          </Button>
        </div>
        {hidecolorPicker ? (
          <div>
            {woodTypes.map((w) => (
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "8px",
                  border:
                    model.woodType && model.woodType.id === w.id
                      ? "5px solid rgb(107,121,196)"
                      : "",
                  margin: "5px",
                  cursor: "pointer",
                }}
                src={BASE_URL + "Uploads/" + w.media.small_path}
                onClick={() =>
                  setmodel((prev) => ({
                    ...prev,
                    woodType: w,
                    color_name_ar: w.name_ar,
                    color_name_fr: w.name_fr,
                    color_name_en: w.name_en,
                    color_code: "",
                    woodTypeId: w.id,
                  }))
                }
                alt=""
              />
            ))}
          </div>
        ) : (
          <div>
            <label>Picker un couleur:</label>
            <SwatchesPicker
              width={"100%"}
              color={model.color_code}
              onChangeComplete={(c) => {
                setmodel((prev) => ({
                  ...prev,
                  color_code: c.hex,
                  woodType: null,
                  woodTypeId: null,
                }));
              }}
            />
          </div>
        )}
      </Responsive>
      <Responsive xl={6} l={6} className="p-10">

        <Responsive className="p-10">
          <label>Prix:</label>
          <Input
            type="number"
            onChange={(price) => {
              setmodel((prev) => {
                return { ...prev, price: parseFloat(price) };
              });
            }}
            value={model.price}
          />
        </Responsive>
        <Responsive className="p-10">
          <label>Prix de Gros:</label>
          <Input
            type="number"
            onChange={(grosPrice) => {
              setmodel((prev) => {
                return { ...prev, grosPrice: parseFloat(grosPrice) };
              });
            }}
            value={model.grosPrice}
          />
        </Responsive>
        <Responsive className="p-10">
          <label>Rémise:</label>
          <Input
            type="number"
            onChange={(discount) => {
              setmodel((prev) => {
                return { ...prev, discount: parseFloat(discount) };
              });
            }}
            value={model.discount}
          />
        </Responsive>
        <Responsive className="p-10">
          <label>onStock:</label>
          <Input
            type="number"
            onChange={(onStock) => {
              setmodel((prev) => {
                return { ...prev, onStock: parseFloat(onStock) };
              });
            }}
            value={model.onStock}
          />
        </Responsive>
      </Responsive>
      <div className="p-10" style={{ background: "rgb(11,150,102,0.15)" }}>
        <h4>Medias</h4>
        <ReactMediaLibraryWrapper
          picker
          reset={model.medias && model.medias.length}
          onPick={(el) => {
            if (
              model.medias &&
              model.medias.find((_el) => _el.mediaId == el.id)
            ) {
              let medias = model.medias.filter((_el) => _el.mediaId != el.id);
              setmodel((prev) => ({ ...prev, medias }));
            } else {
              let medias = model.medias ? [...model.medias] : [];
              medias.push({ mediaId: el.id, media: el });
              setmodel((prev) => ({ ...prev, medias }));
            }
          }}
          selected={selected}
        />
      </div>
      <div style={{ textAlign: "right" }} className="p-10">
        <button
          style={{
            color: "#fff",
            background: "#0b9666",
            padding: "6px 12px",
            borderRadius: "5px",
          }}
          onClick={() => {
            if (model.id) {
              modify(model);
            } else {
              add(model);
            }
            setmodel({
              color_code: "",
              color_name_ar: "",
              color_name_en: "",
              color_name_fr: "",

              // type_name_ar: "",
              // type_name_en: "",
              // type_name_fr: "",
              medias: [],
              onStock: 1,
              price: 0,
              discount: 0,
              reference: "",
            });
          }}
        >
          {model.id ? "modifier" : "ajouter"}
        </button>
      </div>
    </>
  );
};

export const ColorMedias = ({ woodTypes }) => {
  const [colors, setcolors] = useState([]);
  const [model, _setmodel] = useRecoilState(productState);
  const [colorType, setcolorType] = useState({
    color_code: "",
    color_name_ar: "",
    color_name_en: "",
    color_name_fr: "",

    // type_name_ar: "",
    // type_name_en: "",
    // type_name_fr: "",
    medias: [],
    onStock: 1,
    price: 0,
    discount: 0,
    reference: "",
    woodType: null,
  });
  useEffect(() => {
    if (model.id) {
      setcolors(model.colorAndTypes);
    }
  }, [model.id]);
  return (
    <>
      <Responsive xl={6} l={6} className="p-10">
        <ColorType
          model={colorType}
          setmodel={setcolorType}
          woodTypes={woodTypes}
          modify={(l) => {
            let _index = colors.findIndex((el) => el.id == l.id);
            let _c = [...colors];
            _c[_index] = l;
            _setmodel((prev) => ({
              ...prev,
              colorAndTypes: _c,
            }));

            setcolors((prev) => _c);
          }}
          add={(l) => {
            let _c = [...colors];
            if (l.id) {
              let index = _c.findIndex((ell) => ell.id == l.id);
              _c[index] = { ...l };
            } else {
              _c.push({ ...l, id: Date.now() });
            }

            _setmodel((prev) => ({
              ...prev,
              colorAndTypes: _c.map((el) => {
                return el;
              }),
            }));

            setcolors((prev) => _c);
          }}
        ></ColorType>
      </Responsive>
      <Responsive xl={6} l={6} className="p-10">
        <div style={{ border: "2px solid #444" }}>
          <Grid
            deleteAction={(id) => {
              let _c = colors.filter((el) => el.id != id);
              setcolors((prev) => [..._c]);
              _setmodel((prev) => ({
                ...prev,
                colorAndTypes: _c,
              }));
            }}
            editAction={(id) => {
              setcolorType(colors.find((el) => el.id == id));
            }}
            actionKey="id"
            noAdvancedActions // for custom advanced actions
            columns={columns}
            rows={colors}
          />
        </div>
      </Responsive>
    </>
  );
};
const columns = [
  {
    value: "color_code",
    value2: "woodType",
    name: "Couleur",
    render: (background, woodType) => {
      return woodType ? (
        <img
          alt=""
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
          }}
          src={BASE_URL + "Uploads/" + woodType.media.small_path}
        ></img>
      ) : (
        <span
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background,
            content: "",
          }}
        ></span>
      );
    },
  },
  {
    value: "color_name_ar",
    name: "Nom Arabe",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "color_name_fr",
    name: "Nom Français",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "color_name_en",
    name: "Nom Englais",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "medias",
    name: "Medias",
    render: (medias) => {
      console.log(medias);
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <AvatarGroup stack> */}
          {medias &&
            medias
              .filter((m, i) => i <= 4)
              .map((m) => (
                <Avatar
                  circle
                  key={m.id}
                  src={BASE_URL + "Uploads/" + m.media.small_path}
                  alt={""}
                />
              ))}
          {medias && medias.length > 5 && (
            <Avatar circle style={{ background: "#111" }}>
              +{medias.length - 5}
            </Avatar>
          )}
          {/* </AvatarGroup> */}
        </div>
      );
    },
  },
];
