export default class PromotionModel {
  productId = 0;
  dateFrom = new Date();
  dateTo = new Date();
  max_onStock = 0;
  min_onStock = 0;

  newPrice = 0;
  discount = 0;

  name_ar = "";
  name_en = "";
  name_fr = "";
  description_ar = "";
  description_en = "";
  description_fr = "";
  cover;
}
