import { useRecoilState } from "recoil";
import { Input, Message } from "rsuite";
import Responsive from "../../Components/Responsive";
import ContentModel from "../../Models/ContentModel";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw ,ContentState as Cs } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "./editor.scss";
import { useState } from "react";
import { ContentState } from "../../Atoms/content.atom";
import ReactMediaLibraryWrapper from "../media_library";
import { useEffect } from "react";
import htmlToDraft from "html-to-draftjs";
function AddEdit({ error }) {
  const [model, setmodel] = useRecoilState(ContentState);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState(() =>
    EditorState.createEmpty()
  );
  const [selected, setselected] = useState();

  useEffect(() => {
    setselected(model.medias ? model.medias.map((el) => el.media) : []);
    console.log(model.medias);
  }, [model.medias]);
  useEffect(() => {
    if (model.id) {
      if (model.content_ar) {
        const contentBlock = htmlToDraft(model.content_ar);
        if (contentBlock) {
          const contentState = Cs.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      }

      if (model.content_fr) {
        const contentBlock1 = htmlToDraft(model.content_fr);
        if (contentBlock1) {
          const contentState = Cs.createFromBlockArray(
            contentBlock1.contentBlocks
          );
          const editorState1 = EditorState.createWithContent(contentState);
          setEditorState1(editorState1);
        }
      }
      if (model.content_en) {
        const contentBlock1 = htmlToDraft(model.content_en);
        if (contentBlock1) {
          const contentState = Cs.createFromBlockArray(
            contentBlock1.contentBlocks
          );
          const editorState1 = EditorState.createWithContent(contentState);
          setEditorState2(editorState1);
        }
      }
    }
  }, [model.id]);
  return (
    <>
      <Responsive xl={6} l={6} className="p-10">
        {" "}
        <label>Lien:</label>
        <Input
          onChange={(path) => {
            setmodel((prev) => {
              return { ...prev, path };
            });
          }}
          block
          value={model.path}
        />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10">
        <label>Tag:</label>
        <Input
          onChange={(tag) => {
            setmodel((prev) => {
              return { ...prev, tag };
            });
          }}
          block
          value={model.tag}
        />
      </Responsive>
      <Responsive xl={4} l={4} className="p-10">
        <label>Nom Arabe:</label>
        <Input
          onChange={(name_ar) => {
            setmodel((prev) => {
              return { ...prev, name_ar };
            });
          }}
          value={model.name_ar}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Nom Français:</label>
        <Input
          onChange={(name_fr) => {
            setmodel((prev) => {
              return { ...prev, name_fr };
            });
          }}
          value={model.name_fr}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Nom Englais:</label>
        <Input
          onChange={(name_en) => {
            setmodel((prev) => {
              return { ...prev, name_en };
            });
          }}
          value={model.name_en}
        />
      </Responsive>
      <hr></hr>
      <Responsive xl={4} l={4} className="p-10">
        <label>Titre Arabe:</label>
        <Input
          onChange={(title_ar) => {
            setmodel((prev) => {
              return { ...prev, title_ar };
            });
          }}
          value={model.title_ar}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Titre Français:</label>
        <Input
          onChange={(title_fr) => {
            setmodel((prev) => {
              return { ...prev, title_fr };
            });
          }}
          value={model.title_fr}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Titre Englais:</label>
        <Input
          onChange={(title_en) => {
            setmodel((prev) => {
              return { ...prev, title_en };
            });
          }}
          value={model.title_en}
        />
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Contenu Arabe:</label>
        <Editor
          textAlignment="right"
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let content_ar = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, content_ar }));
            setEditorState(st);
          }}
        />
        {/* <textarea
      disabled
      value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    /> */}
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Contenu Français:</label>

        <Editor
          editorState={editorState1}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let content_fr = draftToHtml(
              convertToRaw(editorState1.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, content_fr }));
            setEditorState1(st);
          }}
        />
        {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
      </Responsive>
      <Responsive className="p-10" xl={4} l={4}>
        <label>Contenu Englais:</label>

        <Editor
          editorState={editorState2}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={(st) => {
            let content_en = draftToHtml(
              convertToRaw(editorState2.getCurrentContent())
            );
            setmodel((prev) => ({ ...prev, content_en }));
            setEditorState2(st);
          }}
        />
        {/* <textarea
disabled
value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
/> */}
      </Responsive>

      <div className="p-10" style={{ background: "rgb(11,150,102,0.15)" }}>
        <h4>Medias</h4>
        <ReactMediaLibraryWrapper
          picker
          reset={model.medias ? model.medias.length : 0}
          onPick={(el) => {
            if (
              model.medias &&
              model.medias.find((_el) => _el.mediaId == el.id)
            ) {
              let medias = model.medias.filter((_el) => _el.mediaId != el.id);
              setmodel((prev) => ({ ...prev, medias }));
            } else {
              let medias = model.medias ? [...model.medias] : [];
              medias.push({ mediaId: el.id, media: el });
              setmodel((prev) => ({ ...prev, medias }));
            }
          }}
          selected={selected}
        />
      </div>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
