import * as React from "react";

function SvgXls(props) {
  return (
    <svg className="icon file-icon file-icon--xls" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.607 24" {...props}>
      <path className="file-icon__shadow" d="M19.592 7.219v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003-5.451-5.599-.001-.001a.338.338 0 0 0-.238-.102h-.001l-.005-.001H2.947a1.71 1.71 0 0 0-1.708 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V7.221v-.002z" />
      <path className="file-icon__outline" d="M18.354 5.951v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003L12.801.104 12.8.103a.338.338 0 0 0-.238-.102h-.001L12.556 0H1.708A1.71 1.71 0 0 0 0 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V5.953l.001-.002zm-5.457-4.768l4.305 4.422h-4.305V1.183zm3.749 20.881H1.708c-.565 0-1.025-.46-1.025-1.025V1.708c0-.565.46-1.025 1.025-1.025h10.506v5.264c0 .189.153.342.342.342h5.115v14.75a1.027 1.027 0 0 1-1.025 1.025z" />
      <path className="file-icon__type" d="M6.916 12.951h.726v.03l-1.186 1.702 1.221 1.752v.02h-.741l-.896-1.316-.896 1.316h-.741v-.02l1.216-1.752-1.181-1.702v-.03h.726l.876 1.307.876-1.307zM8.774 12.951v2.894h1.802v.61H8.113v-3.504h.661zM13.251 13.798c-.12-.2-.45-.391-.831-.391-.49 0-.726.205-.726.466 0 .305.36.39.78.439.731.091 1.412.281 1.412 1.116 0 .781-.691 1.116-1.472 1.116-.716 0-1.267-.22-1.527-.86l.551-.285c.154.385.561.556.985.556.416 0 .806-.146.806-.526 0-.33-.345-.465-.811-.515-.716-.086-1.376-.275-1.376-1.062 0-.721.71-1.016 1.356-1.021.545 0 1.11.154 1.376.695l-.523.272z" />
    </svg>
  );
}

export default SvgXls;
