export default class ProductModel {
  name_ar = "";
  name_en = "";
  name_fr = "";
  description_ar = "";
  description_en = "";
  description_fr = "";
  defaultPicture = null;
  defaultPictureId = 0;
  categoryId = 0;
  category = null;
  productTags = null;
  colorAndTypes = [];
  isNew = false;
  storeId = 4;
}
