export default class ContentModel {
  path = "";
  name_ar = "";
  name_en = "";
  name_fr = "";
  title_ar = "";
  title_en = "";
  title_fr = "";
  content_ar = "";
  content_en = "";
  content_fr = "";
  medias = [];
  tag = "";
}
