import axios from "axios";
import React, { useEffect, useState } from "react";

import { Pagination } from "rsuite";
import { APi } from "../../Api";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import AnisMediaLibrary from "../../Components/media_library";
import { BASE_URL } from "../../Config/api.config";

const ReactMediaLibraryWrapper = ({ picker, onPick, reset, selected }) => {
  const [medias, setmedias] = useState();

  const [totalCount, settotalCount] = useState(0);
  const [filter, setfilter] = useState({ type: "", page: 1, take: 20 });

  async function get() {
    let res = await createAPIEndpoint(
      BASE_URL + "api/media",
      filter
    ).fetchAll();
    setmedias(res.data.data);
    settotalCount(res.data.totalCount);
  }
  useEffect(() => {
    get();
  }, [filter]);

  return (
    <React.Fragment>
      <AnisMediaLibrary
        selected={selected}
        reset={reset}
        picker={picker}
        onPick={onPick}
        setfilter={setfilter}
        filter={filter}
        refresh={get}
        medias={medias}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filter.take}
          activePage={filter.page}
          onChangePage={(page) =>
            setfilter((prev) => {
              return { ...prev, page };
            })
          }
          onChangeLimit={(take) => {
            console.log(take);
            setfilter((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ReactMediaLibraryWrapper;

export const MED = () => <ReactMediaLibraryWrapper />;
