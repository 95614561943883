import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import { BASE_URL } from "../../Config/api.config";
import validate from "../../Helpers/validate";
import AddEdit from "./addEdit.component";
const initModel = {
  name_ar: "",
  name_en: "",
  name_fr: "",
  media: null,
  mediaId: null,
};
export default function WoodTypes(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({ q: "" });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(initModel);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(initModel);
    setError("");
  }; // API CALLS
  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.WoodType, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data);
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    let m = { ...model };
    delete m.media;
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.WoodType)
        .update(model.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.WoodType)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.WoodType)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setmodel(data.find((el) => el.id == id));
    setError("");
  };
  // LIFE CYCLES
  useEffect(() => fetch(), []);
  return (
    <div>
      <Filter search={() => fetch()}>
        {" "}
        <div className="p-10">
          {" "}
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </div>
      </Filter>
      <ExportAdd
        size="md"
        noExport
        save={save}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
    </div>
  );
}

const columns = [
  {
    value: "media",
    name: "Image",
    render: (m) =>
      m ? (
        <img
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
          }}
          src={BASE_URL + "Uploads/" + m.small_path}
        ></img>
      ) : (
        <span
          style={{
            display: "inline-block",
            width: "50px",
            height: "50px",
            borderRadius: "5px",
            background: "#eee",
            content: "",
          }}
        ></span>
      ),
  },
  {
    value: "name_ar",
    name: "Nom Arabe",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "name_fr",
    name: "Nom Français",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "name_en",
    name: "Nom Englais",
    render: (v) => <a>{v}</a>,
  },
];
