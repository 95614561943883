import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Input, Message, SelectPicker, TagInput } from "rsuite";
import { APi } from "../../Api";
import { PromoCodeState } from "../../Atoms/promoCode.atom";
import { DateRange } from "react-date-range";
function AddEdit({ error }) {
  const [model, setmodel] = useRecoilState(PromoCodeState);
  const [products, setproducts] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  // const [q, setq] = useState("");
  const fetchProducts = (q) => {
    if (q.length >= 3) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Product, { q, page: 1, take: 20 })
        .fetchAll()
        .then((res) => {
          let _d = res.data.map((el) => ({ label: el.name_fr, value: el.id }));
          setproducts(res.data);
        })
        .catch((e) => console.log(e.Message));
    }
  };

  return (
    <>
      <label>Article :</label>
      <SelectPicker
        value={model.ProductId}
        onSelect={(ProductId) => setmodel((prev) => ({ ...prev, ProductId }))}
        defaultValue=""
        data={products.map((el) => ({ label: el.name_fr, value: el.id }))}
        onSearch={(q) => fetchProducts(q)}
        block
      />
      <label>Code :</label>
      <Input
        disabled
        // onChange={(code) => {
        //   setmodel((prev) => {
        //     return { ...prev, code };
        //   });
        // }}
        value={model.code}
      />
      <label>Nbre des articles :</label>
      <Input
        type="number"
        value={model.max_use}
        onChange={(max_use) => {
          setmodel((prev) => {
            return { ...prev, max_use };
          });
        }}
      />
      <label>Interval de temps :</label>
      {/*  */}
      {/* {model.endDate.toString()} */}
      <br></br>
      <div>
        <DateRange
          editableDateInputs={true}
          onChange={(item) =>
            // setState([item])
            setmodel((pv) => ({
              ...pv,
              beginDate: item.selection.startDate,
              endDate: item.selection.endDate,
            }))
          }
          moveRangeOnFirstSelection={false}
          // ranges={state}
          ranges={[
            {
              startDate: new Date(model.beginDate),
              endDate: new Date(model.endDate),
              key: "selection",
            },
          ]}
        />
      </div>
      <br></br>
      {/* {model.productId ? (
        <div>
          {" "}
          ancien prix : {
            products.find((el) => el.id == model.productId).price
          }{" "}
        </div>
      ) : (
        ""
      )}{" "} */}
      <label>Remise %:</label>
      <Input
        type="number"
        value={model.discount}
        onChange={(discount) => {
          setmodel((prev) => {
            return { ...prev, discount };
          });
        }}
      />
      {/* <label>Adresse :</label>
      <Input
        value={model.address}
        onChange={(address) => {
          setmodel((prev) => {
            return { ...prev, address };
          });
        }}
      /> */}
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
