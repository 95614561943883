import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  Content,
  Dropdown,
  Sidenav,
  Nav,
  Navbar,
  Sidebar,
  IconButton,
} from "rsuite";
import { Dashboard } from "@rsuite/icons";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import DeviceOtherIcon from "@rsuite/icons/DeviceOther";
import CreditCardMinusIcon from "@rsuite/icons/CreditCardMinus";
import BranchIcon from "@rsuite/icons/Branch";
import SiteFillIcon from "@rsuite/icons/SiteFill";
import MemberIcon from "@rsuite/icons/Member";
import { Speaker, Media } from "@rsuite/icons";
import PeoplesIcon from "@rsuite/icons/Peoples";
import PageNextIcon from "@rsuite/icons/PageNext";
import GearIcon from "@rsuite/icons/Gear";
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch";
import DocPassIcon from "@rsuite/icons/DocPass";
import { useLocation } from "react-router-dom";
import ViewsAuthorizeIcon from "@rsuite/icons/ViewsAuthorize";
import CouponIcon from "@rsuite/icons/Coupon";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { BsGraphUp, BsMenuButton } from "react-icons/bs";
import "./App.scss";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Link,
  Redirect,
} from "react-router-dom";

import PageIcon from "@rsuite/icons/Page";
import ProjectIcon from "@rsuite/icons/Project";
import Login from "./Screens/Auth/login";
import { RecoilState, useRecoilState, useSetRecoilState } from "recoil";
import { isLogged } from "./Atoms/auth.atom";
import { FacebookProvider } from "react-facebook";

import { PageTitle } from "./Atoms/page.title.atom";

import { APi } from "./Api";

import "../node_modules/react-vis/dist/style.css";

import Users from "./Screens/Users";
import Products from "./Screens/cms/products";
import Categories from "./Screens/cms/categories";
import Orders from "./Screens/orders";
import ReactMediaLibraryWrapper, { MED } from "./Screens/media_library";
import Contents from "./Screens/Content";
import Deliveries from "./Screens/Deliveries";
import Promotions from "./Screens/Promotions";
import PromoCodes from "./Screens/PromoCodes";
import Customers from "./Screens/Customers";
import WoodTypes from "./Screens/WoodType";
import Stores from "./Screens/stores";
import OurStore from "./Screens/OurStore";
import DeliveryCompany from "./Screens/DeliveryCompany";
import ProductDeliveryType from "./Screens/ProductDeliveryTypes";
import Announcements from "./Screens/Announcement";
import axios from "axios";
const iconStyles = {
  width: 56,
  height: 56,
  padding: 18,
  lineHeight: "56px",
  textAlign: "center",
};
const access =
  "EAANYBzomEfEBAGZCQLoqaB50xG2fKlZA2jXZAI0iYUWLPxNqU8UfEXE0eZCH5zGt8NJI8eYGkM9o8KlyoUob3CIZAhlOj15DE2jarAiDo1C6HVREFdZClbNt1KLN0ZAEnixmysDiNqN3DZBftveZAVHqBY68nMB2p3tzJDTC1Y8yxI2yGwrno2XiBbX0cmf5tIZAoSQauwt7WrSLNHXq6ySlc8uubK7wCd5WMZD";

const App = (props) => {
  const [expand, setExpand] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [active, setactive] = useState(1);
  const [logged, setlogged] = useRecoilState(isLogged);
  const [pageTitle, setpageTitle] = useState("Produits");
  const [access_token, setaccess_token] = useState("");
  const location = useLocation();
  
  const getToken = () => {
    axios
      .get(
        "https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=1180402002867964&client_secret=7a8648b00d0ac613906f44bcdecc6c21&fb_exchange_token=EAAQxkcIStvwBAFT52IoSxZAOOXYRnvXiu6KQoUzRmAzKNRlMfHNMuMxB4OTE8lceFku4YeJZCeftx7qZAK9b7wNrXuD74uIetMZBj8UMiUNE1H3lcZBmvJcNNh1U7yZBMtbmTtZCCnpyBCk1ZC3iqE7ZAsE8gJyn2rZCXRZCnZCu1UF0nrEr1gX92goTh4Yp1LeuSfppJ6nkxd0yZAn7a1HIplXnL"
      )
      .then((res) => {
        console.log(res.data.access_token);
        localStorage.setItem("fb_access_token", res.data.access_token);
      });
  };
  const getpageToken = (pageid = "1707400729561807") => {
    axios
      .get(
        "https://graph.facebook.com/" +
          pageid +
          "?grant_type=pages_read_engagement,pages_manage_posts&fields=access_token&access_token=" +
          access
      )
      .then((res) => {
        localStorage.setItem(
          "fb_page_access_token",
          JSON.stringify(res.data.access_token)
        );
        console.log(res.data);
        setaccess_token(res.data.access_token);
      });
  };
  const getpageInfos = (pageid = "1707400729561807") => {
    axios
      .get(
        "https://graph.facebook.com/" +
          pageid +
          "?fields=about,attire,bio,location,parking,hours,emails,website&access_token=" +
          access_token
      )
      .then((res) => console.log(res));
  };
  useEffect(() => {
    getpageToken();
    getToken();
  }, []);
  useEffect(() => {
    getpageInfos();
  }, [access_token]);
  useEffect(() => {
    // getToken();
    // console.log(accessToken);
    // FB.setAccessToken(accessToken);
    // FB.api("", function (res) {
    //   if (!res || res.error) {
    //     console.log(!res ? "error occurred" : res.error);
    //     return;
    //   }
    //   console.log(res);
    //   // console.log(res.name);
    // });
    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    } else {
    }
    setlogged(log);
    console.log(log);
    setTimeout(() => {
      setloaded(true);
      setpageTitle(routes[location.pathname]);
    }, 1000);
  }, [logged]);
  useEffect(() => {
    setExpand(false);
  }, [pageTitle]);
  const AuthGard = (Screen) => (logged ? Screen : <Login />);
  return (
    <FacebookProvider appId="798812938514190">
      <div className="app">
        <Container>
          {logged && (
            <Sidebar className={"app-sidebar " + (expand ? "show" : "")}>
              <Sidenav.Header>
                <div className="app-sidebar-header">
                  <div>
                    <BsGraphUp style={{ fontSize: 20 }} />
                    <b style={{ marginLeft: 12, fontSize: "large" }}>
                      {" "}
                      Le royaume du bois
                    </b>{" "}
                  </div>{" "}
                  <button
                    className="close_menu_btn"
                    onClick={(e) => setExpand((prev) => !prev)}
                  >
                    x
                  </button>
                </div>
              </Sidenav.Header>
              <Sidenav defaultOpenKeys={["3"]} appearance="subtle">
                <Sidenav.Body>
                  <Nav>
                    <Nav.Item
                      onClick={() => {
                        // setExpand(false);
                        setactive("277727");
                        setpageTitle("Notre Boutique");
                      }}
                      active={active == "27777"}
                      eventKey="277727"
                      icon={<Dashboard size="3em" />}
                    >
                      <Link className={"side_link "} to="/our_store">
                        Notre Boutique
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        // setExpand(false);
                        setactive("27777");
                        setpageTitle("Portal Géneral");
                      }}
                      active={active == "27777"}
                      eventKey="27777"
                      icon={<Dashboard size="3em" />}
                    >
                      <Link className={"side_link "} to="/contents">
                        Portal Géneral{" "}
                        <i style={{ fontSize: "11px" }}>( pages statiques)</i>
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        // setExpand(false);
                        setactive("2");
                        setpageTitle("Commandes");
                      }}
                      active={active == "2"}
                      eventKey="2"
                      icon={<CreditCardPlusIcon size="3em" />}
                    >
                      <Link className={"side_link "} to="/orders">
                        Commandes
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        // setExpand(false);
                        setactive("2g");
                        setpageTitle("Commandes En Gros");
                      }}
                      active={active == "2g"}
                      eventKey="2g"
                      icon={<CreditCardPlusIcon size="3em" />}
                    >
                      <Link className={"side_link "} to="/orders_gros">
                        Commandes En Gros
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => {
                        // setExpand(false);
                        setactive("1112");
                        setpageTitle("Sociétés de livraison");
                      }}
                      active={active == "1112"}
                      eventKey="1112"
                      icon={<CreditCardPlusIcon size="3em" />}
                    >
                      <Link className={"side_link "} to="/delivery_company">
                        Sociétés de livraison
                      </Link>
                    </Nav.Item>
                    {/* <Nav.Item
                    onClick={() => {
                   // setExpand(false);  setactive("27");
                      setpageTitle("Livraisons");
                    }}
                    active={active == "27"}
                    eventKey="27"
                    icon={<SiteFillIcon size="3em" />}
                  >
                    <Link className={"side_link "} to="/deliveries">
                      Livraisons <i style={{ fontSize: "11px" }}>( soon )</i>
                    </Link>
                  </Nav.Item> */}
                    {/* <Dropdown
                    eventKey="6"
                    trigger="hover"
                    title="Content Management"
                    icon={<ProjectIcon size="3em" />}
                    placement="rightStart"
                  > */}
                    <Nav.Item
                      eventKey="6-1"
                      onClick={() => {
                        // setExpand(false);
                        setactive("6-1");
                        setpageTitle("Produits");
                      }}
                      icon={<DeviceOtherIcon size="3em" />}
                      active={active == "6-1"}
                    >
                      <Link className={"side_link "} to="/products">
                        Produits
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="21212121212"
                      onClick={() => {
                        // setExpand(false);
                        setactive("21212121212");
                        setpageTitle("Type du produit");
                      }}
                      icon={<DeviceOtherIcon size="3em" />}
                      active={active == "21212121212"}
                    >
                      <Link className={"side_link "} to="/delivery_type">
                        Type du produit
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="6-27"
                      onClick={() => {
                        // setExpand(false);
                        setactive("6-27");
                        setpageTitle("Catégories");
                      }}
                      active={active == "6-27"}
                      icon={<BranchIcon size="3em" />}
                    >
                      <Link className={"side_link "} to="/categories">
                        Catégories
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="6-27"
                      onClick={() => {
                        // setExpand(false);
                        setactive("6-27");
                        setpageTitle("Types Du Bois");
                      }}
                      active={active == "6-27"}
                      icon={<BranchIcon size="3em" />}
                    >
                      <Link className={"side_link "} to="/wood_types">
                        Types Du Bois
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="6-2"
                      onClick={() => {
                        // setExpand(false);
                        setactive("6-2");
                        setpageTitle("Bibliptéque de media");
                      }}
                      active={active == "6-2"}
                      icon={<Media />}
                    >
                      <Link className={"side_link "} to="/medias">
                        Bibliotéque des medias
                      </Link>
                    </Nav.Item>
                    {/* </Dropdown> */}
                    <Nav.Item
                      eventKey="55-11"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-11");
                        setpageTitle(" Clients");
                      }}
                      icon={<PeoplesIcon size="3em" />}
                      active={active == "55-11"}
                    >
                      <Link className={"side_link "} to="/customers">
                        {" "}
                        Clients
                      </Link>
                    </Nav.Item>
                    {/* Announcements */}
                    <Nav.Item
                      eventKey="55-1188"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-1188");
                        setpageTitle(" Promotions");
                      }}
                      icon={<Speaker />}
                      active={active == "55-1188"}
                    >
                      <Link className={"side_link "} to="/promotions">
                        {" "}
                        Promotions
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="55-118d8"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-118d8");
                        setpageTitle(" Annonces");
                      }}
                      icon={<Speaker />}
                      active={active == "55-118d8"}
                    >
                      <Link className={"side_link "} to="/announcements">
                        {" "}
                        Annonces
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="55-118"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-118");
                        setpageTitle(" Promo Codes ");
                      }}
                      icon={<CouponIcon size="3em" />}
                      active={active == "55-118"}
                    >
                      <Link className={"side_link "} to="/promocodes">
                        {" "}
                        Promo Codes
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="55-1888"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-1888");
                        setpageTitle(" Utilisateurs");
                      }}
                      icon={<MemberIcon size="3em" />}
                      active={active == "55-1888"}
                    >
                      <Link className={"side_link "} to="/users">
                        {" "}
                        Utilisateurs
                      </Link>
                    </Nav.Item>
                    <Nav.Item
                      eventKey="55-1888a"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-1888a");
                        setpageTitle("Vendeurs");
                      }}
                      icon={<MemberIcon size="3em" />}
                      active={active == "55-1888a"}
                    >
                      <Link className={"side_link "} to="/vendors">
                        {" "}
                        Vendeurs
                      </Link>
                    </Nav.Item>
                    {/* <h6
                    style={{
                      border: "1px solid #eee",
                      background: "rgb(252,179,34,0.2)",
                      borderRight: "0 none",
                    }}
                    className="p-10"
                  >
                    Pour le SUPER ADMIN
                  </h6> */}
                    <Nav.Item
                      eventKey="55-1"
                      onClick={() => {
                        // setExpand(false);
                        setactive("55-1");
                        setpageTitle(" Boutiques");
                      }}
                      icon={<Dashboard />}
                      active={active == "55-1"}
                    >
                      <Link className={"side_link "} to="/stores">
                        {" "}
                        Les Boutiques{" "}
                        <i style={{ fontSize: "11px" }}>( soon )</i>
                      </Link>
                    </Nav.Item>
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
              {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
            </Sidebar>
          )}

          <Container className={"hole-container"}>
            {logged && (
              <Header className="page-header">
                <h4>{pageTitle}</h4>

                <div
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <button
                    className="menu_btn"
                    onClick={(e) => setExpand((prev) => !prev)}
                  >
                    <BsMenuButton />
                  </button>
                  <Nav>
                    <Dropdown
                      placement="bottomEnd"
                      trigger="click"
                      icon={<GearIcon size="3em" />}
                      renderTitle={(children) => {
                        return <GearIcon style={iconStyles} />;
                      }}
                    >
                      {/* <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.removeItem("auth");
                          setlogged(false);
                        }}
                      >
                        Sign out
                      </Dropdown.Item>
                    </Dropdown>
                  </Nav>
                </div>
              </Header>
            )}

            <Content className={"app-content " + (!logged ? "" : "logged")}>
              <Switch>
                <Route
                  path="/products"
                  render={(props) => AuthGard(<Products {...props} />)}
                />
                <Route
                  path="/categories"
                  render={(props) => AuthGard(<Categories {...props} />)}
                />
                <Route
                  path="/stores"
                  render={(props) => AuthGard(<Stores {...props} />)}
                />
                <Route
                  path="/our_store"
                  render={(props) => AuthGard(<OurStore {...props} />)}
                />
                <Route
                  path="/orders"
                  render={(props) => AuthGard(<Orders {...props} />)}
                />
                <Route
                  path="/orders_gros"
                  render={(props) =>
                    AuthGard(<Orders isGros={true} {...props} />)
                  }
                />
                <Route
                  path="/users"
                  render={(props) => AuthGard(<Users {...props} />)}
                />
                <Route
                  path="/vendors"
                  render={(props) =>
                    AuthGard(<Users isVendor={true} {...props} />)
                  }
                />
                <Route
                  path="/medias"
                  render={(props) => AuthGard(<MED {...props} />)}
                />
                <Route
                  path="/contents"
                  render={(props) => AuthGard(<Contents {...props} />)}
                />
                <Route
                  path="/deliveries"
                  render={(props) => AuthGard(<Deliveries {...props} />)}
                />
                <Route
                  path="/promotions"
                  render={(props) => AuthGard(<Promotions {...props} />)}
                />{" "}
                <Route
                  path="/announcements"
                  render={(props) => AuthGard(<Announcements {...props} />)}
                />
                <Route
                  path="/promocodes"
                  render={(props) => AuthGard(<PromoCodes {...props} />)}
                />
                <Route
                  path="/customers"
                  render={(props) => AuthGard(<Customers {...props} />)}
                />
                <Route
                  path="/wood_types"
                  render={(props) => AuthGard(<WoodTypes {...props} />)}
                />
                <Route
                  path="/delivery_company"
                  render={(props) => AuthGard(<DeliveryCompany {...props} />)}
                />
                <Route
                  path="/delivery_type"
                  render={(props) =>
                    AuthGard(<ProductDeliveryType {...props} />)
                  }
                />
                <Route
                  path="/"
                  render={(props) => AuthGard(<Products {...props} />)}
                />
                <Route
                  path="/*"
                  render={(props) => AuthGard(<Products {...props} />)}
                />
              </Switch>
            </Content>
          </Container>
        </Container>
      </div>{" "}
    </FacebookProvider>
  );
};
const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? (
            <PagePreviousIcon size="3em" />
          ) : (
            <PageNextIcon size="3em" />
          )}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};
export default App;

const routes = {
  "/tickets": "Tickets",
  "/tiers/clients": "Clients",
  "/checks": " Gestion des chéques",
  "/treasory/banks": "Banques",
  "/treasory/checkouts": "Caisses",
  "/invoices": "Factures",
  "/invoices/settings": "Paramétrage Facture",
};
