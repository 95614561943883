import * as React from "react";

function SvgDoc(props) {
  return (
    <svg className="icon file-icon file-icon--doc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.607 24" {...props}>
      <path className="file-icon__shadow" d="M19.592 7.219v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003-5.451-5.599-.001-.001a.338.338 0 0 0-.238-.102h-.001l-.005-.001H2.947a1.71 1.71 0 0 0-1.708 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V7.221v-.002z" />
      <path className="file-icon__outline" d="M18.354 5.951v-.004c0-.014-.006-.026-.008-.039-.004-.03-.006-.06-.018-.089a.318.318 0 0 0-.055-.085c-.006-.008-.009-.017-.016-.025l-.002-.003-.003-.003L12.801.104 12.8.103a.338.338 0 0 0-.238-.102h-.001L12.556 0H1.708A1.71 1.71 0 0 0 0 1.708v19.331a1.71 1.71 0 0 0 1.708 1.708h14.937a1.71 1.71 0 0 0 1.708-1.707V5.953l.001-.002zm-5.457-4.768l4.305 4.422h-4.305V1.183zm3.749 20.881H1.708c-.565 0-1.025-.46-1.025-1.025V1.708c0-.565.46-1.025 1.025-1.025h10.506v5.264c0 .189.153.342.342.342h5.115v14.75a1.027 1.027 0 0 1-1.025 1.025z" />
      <path className="file-icon__type" d="M7.104 14.674c.015.886-.525 1.781-1.751 1.781H3.977v-3.504h1.376c1.201 0 1.736.857 1.751 1.723zm-2.472 1.145h.721c.796 0 1.111-.58 1.096-1.151-.015-.545-.335-1.091-1.096-1.091h-.721v2.242zM11.079 14.724c-.01.905-.565 1.812-1.797 1.812-1.231 0-1.802-.886-1.802-1.807s.591-1.847 1.802-1.847c1.207 0 1.808.926 1.797 1.842zm-2.948.014c.015.576.325 1.191 1.151 1.191s1.136-.621 1.146-1.196c.01-.591-.32-1.251-1.146-1.251-.825 0-1.166.665-1.151 1.256zM14.699 16.015c-.36.355-.826.521-1.331.521-1.302 0-1.853-.896-1.857-1.807-.005-.916.591-1.847 1.857-1.847.476 0 .926.18 1.286.535l-.44.426a1.201 1.201 0 0 0-.846-.331c-.846 0-1.212.631-1.206 1.217.005.58.34 1.186 1.206 1.186.305 0 .65-.125.881-.354l.45.454z" />
    </svg>
  );
}

export default SvgDoc;
