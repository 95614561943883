import { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Message, SelectPicker, TagInput } from "rsuite";
import { APi } from "../../Api";
import { CustomerState } from "../../Atoms/customer.atom";
import { orderState } from "../../Atoms/odrder.atom";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import { gouvernorates } from "../../Constants/gouvernorate";

function AddEdit({ error }) {
  const [model, setmodel] = useRecoilState(orderState);
  const [item, setitem] = useState({
    productId: 0,
    quantity: 1,
    colorAndTypeId: 0,
  });
  const [products, setproducts] = useState([]);

  const fetchProducts = (q) => {
    if (q.length >= 3) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Product + "/GetColotypes", {
        q,
        page: 1,
        take: 20,
      })
        .fetchAll()
        .then((res) => {
          let _d = res.data.map((el) => ({ label: el.name_fr, value: el.id }));
          setproducts(res.data);
        })
        .catch((e) => console.log(e.Message));
    }
  };
  return (
    <>
      <h2>Articles :</h2>
      <Responsive m={6} l={4} xl={4} className="p-5">
        <SelectPicker
          value={item.productId}
          onSelect={(productId) =>
            setitem((prev) => ({
              ...prev,
              productId,
              product: products.find((p) => p.id == productId),
            }))
          }
          defaultValue=""
          data={products.map((el) => ({ label: el.name_fr, value: el.id }))}
          onSearch={(q) => fetchProducts(q)}
          block
        />
      </Responsive>
      {item.productId ? (
        <Responsive m={6} l={4} xl={4} className="p-5">
          <SelectPicker
            value={item.colorAndTypeId}
            onSelect={(colorAndTypeId) =>
              setitem((prev) => ({ ...prev, colorAndTypeId }))
            }
            defaultValue=""
            data={products
              .find((el) => el.id == item.productId)
              .colorAndTypes.map((el) => ({
                label: el.color_name_fr,
                value: el.id,
              }))}
            block
          />
        </Responsive>
      ) : (
        ""
      )}
      <Responsive s={6} m={6} l={2} xl={2} className="p-5">
        <Input
          placeholder="quantité"
          type="number"
          value={item.quantity}
          onChange={(quantity) => {
            setitem((prev) => ({ ...prev, quantity }));
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-5">
        <Button
          appearance="primary"
          color="green"
          onClick={() => {
            let items = [...model.items];
            items.push({
              ...item,
              id: "" + Date.now(),
              unitPrice: item.product.colorAndTypes.find(
                (c) => c.id == item.colorAndTypeId
              ).price,
            });
            setitem({
              productId: 0,
              quantity: 1,
              colorAndTypeId: 0,
            });
            setmodel((prev) => ({ ...prev, items }));
          }}
        >
          +
        </Button>
      </Responsive>
      <hr></hr>
      {/* {model.items.map((p) => (
        <div style={{ background: "#eee", borderTop: "1px solid #aaa" }}>
          <span>{p.product.name_fr}</span>
        </div>
      ))} */}
      <div style={{ border: "2px solid #444" }}>
        <Grid
          deleteAction={(id) => {
            let _c = model.items.filter((el) => el.id != id);
            setmodel((prev) => ({ ...prev, items: _c }));
          }}
          actionKey="id"
          noAdvancedActions // for custom advanced actions
          columns={columns}
          rows={model.items}
        />
      </div>
      <hr></hr>
      <h2>Client :</h2>
      <label>Nom Complet :</label>
      <Input
        onChange={(fullName) => {
          setmodel((prev) => {
            return { ...prev, customer: { ...prev.customer, fullName } };
          });
        }}
        value={model.customer?.fullName}
      />
      <label>Email :</label>
      <Input
        type="email"
        value={model.customer?.email}
        onChange={(email) => {
          setmodel((prev) => {
            return { ...prev, customer: { ...prev.customer, email } };
          });
        }}
      />
      <label>Télephone :</label>
      <Input
        value={model.customer?.phoneNumber}
        onChange={(phoneNumber) => {
          setmodel((prev) => {
            return { ...prev, customer: { ...prev.customer, phoneNumber } };
          });
        }}
      />{" "}
      <label>Télephone 2 :</label>
      <Input
        value={model.customer?.phoneNumber2}
        onChange={(phoneNumber) => {
          setmodel((prev) => {
            return { ...prev, customer: { ...prev.customer, phoneNumber } };
          });
        }}
      />
      <label>Adresse :</label>
      <Input
        value={model.customer?.address}
        onChange={(address) => {
          setmodel((prev) => {
            return { ...prev, customer: { ...prev.customer, address } };
          });
        }}
      />
      <label
        class="mb-2 text-sm mt-3 block text-base font-medium text-black"
        for="cityselect"
      >
        Gouvernerat
      </label>
      <SelectPicker
        value={model.customer?.city}
        onSelect={(city) =>
          setmodel((prev) => {
            return {
              ...prev,
              customer: { ...prev.customer, city },
            };
          })
        }
        defaultValue=""
        data={gouvernorates.map((el) => ({
          label: el,
          value: el,
        }))}
        block
      />
      <label className="mb-2 text-sm mt-3 block text-base font-medium text-black required">
        Zip Code:
      </label>
      <Input
        value={model.customer?.zipCode}
        onChange={(zipCode) =>
          setmodel((prev) => {
            return {
              ...prev,
              customer: { ...prev.customer, zipCode },
            };
          })
        }
        required
        type="number"
        name="zipCode"
        className="h-10 text-sm w-full   px-5 border border-gray-200  rounded-md"
      />
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
const columns = [
  {
    value: "product",
    value2: "colorAndTypeId",
    name: "Article",
    render: (p, el) => {
      return (
        <div style={{ display: "flex" }}>
          {" "}
          <img
            alt=""
            style={{
              display: "inline-block",
              width: "50px",
              height: "50px",
              borderRadius: "5px",
              background: "#eee",
            }}
            src={BASE_URL + "Uploads/" + p.defaultPicture.path}
          ></img>
          <div className="p-5">
            <span>{p.name_fr}</span>
            <br></br>
            <span style={{ color: "gray" }}>
              {p.colorAndTypes.find((c) => c.id == el).color_name_fr}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    value: "unitPrice",
    value2: "colorAndTypeId",
    name: "Prix Unitaire",
    render: (p, el) => (
      <span style={{ color: "green", fontSize: "20px", fontWeight: "bold" }}>
        {p}
      </span>
    ),
  },
  {
    value: "quantity",
    name: "Qunatité",
    render: (v) => <b>{v}</b>,
  },
  {
    value: "unitPrice",
    value2: "quantity",
    name: "Prix Total",
    render: (p, v) => (
      <span style={{ color: "green", fontSize: "20px", fontWeight: "bold" }}>
        {p * v}
      </span>
    ),
  },
];
